import { motion } from "framer-motion";
import { Link } from "react-router-dom";
// import HeaderImg from '../assets/images/headerImg.png'
import WhiteArrow from "../assets/images/button-arrow.png";
import A1Logo from "../assets/images/card-a1tutor.png";
import PurpleArrow from "../assets/images/purple-arrow.png";
import BubbleSection from "./BubbleSection";
import IntroTitle from "./shared/IntroTitle";

const HomeHeader = ({ downloadLink }) => {
  return (
    <header className="container-fluid home-header">
      <BubbleSection />

      <div className="container header-content">
        <div className="row">
          <div className="col-lg-7 mx-auto text-center mb-5">
            <IntroTitle>
              {" "}
              Learning <br /> just for you
            </IntroTitle>
            <motion.h5
              initial={{ y: -50, opacity: 0.1 }}
              whileInView={{ y: 0, opacity: 1, transition: { duration: 0.3 } }}
              exit={{ y: -50, opacity: 0.1 }}
            >
              <span className="fw-bold">•</span> WAEC &nbsp;
              <span className="fw-bold">•</span> JAMB &nbsp;
              <span className="fw-bold">•</span> BECE &nbsp;
              <span className="fw-bold">•</span> A-LEVELS &nbsp;
              <span className="fw-bold">•</span> IGSCE
            </motion.h5>

            <div className="d-flex justify-content-center align-items-center gap-1 gap-md-3 mt-4 mt-md-5">
              <Link to="get-started" style={{ textDecoration: "none" }}>
                <button
                  className="main-btn justify-content-center d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  <span>Get Started</span>
                  <span>
                    <img src={WhiteArrow} alt="" style={{ width: "14px" }} />
                  </span>
                </button>
              </Link>
              <a href={downloadLink} style={{ textDecoration: "none" }}>
                <button
                  className="purple-outline-btn d-flex gap-1 align-items-center justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <span>Download App</span>
                  <span>
                    <img src={PurpleArrow} alt="" style={{ width: "16px" }} />
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="py-5 mb-5">
          {/* <motion.img initial={{scale: 1.3, opacity: 0.1}} whileInView={{scale: 1, opacity: 1,  transition: { duration: 0.3}}} src={HeaderImg} alt="" /> */}
          {/* <img data-aos="zoom-in" src={HeaderImg} alt="" /> */}
          <div className="col-xl-7 col-lg-9  mx-auto">
            <div className="row g-4 gx-5">
              <div className="col-md-6 col-sm-8 col-11 mx-auto">
                <div className="a1-box p-4 py-5">
                  <div className="mb-3">
                    <img src={A1Logo} alt="" style={{ width: "110px" }} />
                  </div>

                  <div className="mb-4">
                    <img
                      src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672086592/A1tutor%20website/header-card1_pugate.png"
                      alt=""
                    />
                  </div>

                  <div className="">
                    <h6 className="mb-3">
                      Get Access to{" "}
                      <span className="tx-blue">Free Live Classes</span> in Real
                      Time
                    </h6>
                    <Link to="get-started" style={{ textDecoration: "none" }}>
                      <button
                        className="main-btn justify-content-center d-flex align-items-center mx-auto"
                        style={{ gap: "5px" }}
                      >
                        <span>Join Now</span>
                        <span>
                          <img
                            src={WhiteArrow}
                            alt=""
                            style={{ width: "14px" }}
                          />
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-8 col-11 mx-auto">
                <div className="a1-box p-4 py-5">
                  <div className="mb-3">
                    <img src={A1Logo} alt="" style={{ width: "110px" }} />
                  </div>

                  <div className="mb-4">
                    <img
                      src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672086485/A1tutor%20website/header-card2_aaxxct.png"
                      alt=""
                    />
                  </div>

                  <div className="">
                    <h6 className="mb-3">
                      Watch <span className="tx-purple">Lesson Videos</span> For
                      different Subjects
                    </h6>
                    <Link to="get-started" style={{ textDecoration: "none" }}>
                      <button
                        className="purple-btn justify-content-center d-flex align-items-center mx-auto"
                        style={{ gap: "5px" }}
                      >
                        <span>Watch Now</span>
                        <span>
                          <img
                            src={WhiteArrow}
                            alt=""
                            style={{ width: "14px" }}
                          />
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-8 col-11 mx-auto">
                <div className="a1-box p-4 py-5">
                  <div className="mb-3">
                    <img src={A1Logo} alt="" style={{ width: "110px" }} />
                  </div>

                  <div className="mb-4">
                    <img
                      src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672086485/A1tutor%20website/header-card3_dqfsen.png"
                      alt=""
                    />
                  </div>

                  <div className="">
                    <h6 className="mb-3">
                      <span className="tx-orange">CBT</span> & Exam Questions
                      for you.
                    </h6>
                    <Link to="get-started" style={{ textDecoration: "none" }}>
                      <button
                        className="orange-btn justify-content-center d-flex align-items-center mx-auto"
                        style={{ gap: "5px" }}
                      >
                        <span>Practice Now</span>
                        <span>
                          <img
                            src={WhiteArrow}
                            alt=""
                            style={{ width: "14px" }}
                          />
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-8 col-11 mx-auto">
                <div className="a1-box p-4 py-5">
                  <div className="mb-3">
                    <img src={A1Logo} alt="" style={{ width: "110px" }} />
                  </div>

                  <div className="mb-4">
                    <img
                      src="https://res.cloudinary.com/aonetutor-ltd/image/upload/v1672086359/A1tutor%20website/header-card4_c3u5yh.png"
                      alt=""
                    />
                  </div>

                  <div className="">
                    <h6 className="mb-3">
                      Get <span className="tx-green">Lesson Notes</span> Based
                      On Your Curriculum
                    </h6>
                    <Link to="get-started" style={{ textDecoration: "none" }}>
                      <button
                        className="green-btn justify-content-center d-flex align-items-center mx-auto"
                        style={{ gap: "5px" }}
                      >
                        <span>Learn More</span>
                        <span>
                          <img
                            src={WhiteArrow}
                            alt=""
                            style={{ width: "14px" }}
                          />
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;

import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import useData from "../../../hooks/useData";
const LOGIN_URL = `user/login`;

const Login = () => {
  const { setUserData, setAuth } = useData();
  const [navigate, setNavigate] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  async function signIn(e) {
    e.preventDefault();
    const id = toast.loading("Signing in, please wait...");

    if (loginData.email === "" || loginData.password === "") {
      toast.update(id, {
        render: "All fields are required",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      return;
    }

    try {
      const response = await axios.post(LOGIN_URL, loginData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*",
        },
      });

      const { access_token, data } = response.data;
      const { email, first_name, last_name, phone } = data;
      setAuth({ email, access_token, first_name, last_name, phone });
      setUserData(data);

      toast.update(id, {
        render: "Login Successful",
        type: "success",
        isLoading: false,
        autoClose: 1500,
      });

      setTimeout(() => {
        toast.update(id, {
          render: "Redirecting to your dashboard",
          type: "info",
          isLoading: false,
          autoClose: 1500,
        });
      }, 1700);

      setTimeout(() => {
        setNavigate(true);
      }, 3300);
      console.log(response);
    } catch (err) {
      setNavigate(false);
      console.log(err);
      toast.update(id, {
        render:
          err?.response?.data?.message ||
          err?.message ||
          "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  }

  if (navigate) {
    return <Navigate to="/dashboard/payments" replace={true} />;
  }
  return (
    <div className="register-form-card p-2 p-md-4 p-xxl-5 col-md-10 col-lg-10 col-xxl-8 mx-auto">
      <div className="register-form-card-header mb-3 mb-xxl-5">
        <h3 className="tx-blue">Login Now</h3>
        <h6>
          Enter Details to{" "}
          <span className="fw-bold">
            <i>get started</i>
          </span>{" "}
        </h6>
      </div>

      <form onSubmit={signIn}>
        <div className="mb-5">
          <div className="mb-4">
            <input
              type="email"
              name="user_email"
              id="user_email"
              placeholder="Email address"
              onChange={(e) =>
                setLoginData({ ...loginData, email: e.target.value })
              }
            />
          </div>

          <div className="mb-4">
            <input
              type="password"
              name="user_password"
              placeholder="Password"
              id="user_password"
              onChange={(e) =>
                setLoginData({ ...loginData, password: e.target.value })
              }
            />
          </div>
        </div>

        <div>
          {/* <Link to="/dashboard/payments">
                    <button className="">Login</button>
                  </Link> */}
          {/* <Link to=""> */}
          <button type="submit" className="">
            Login
          </button>
          {/* </Link> */}
        </div>
      </form>

      <div className="mt-3 text-center">
        {/* <p style={{ letterSpacing: "-1px", color: "#aaa" }}>
          Don't have an account?{" "}
          <Link to="/get-started" className="tx-blue">
            Create Account
          </Link>
        </p> */}
      </div>
    </div>
  );
};

export default Login;
